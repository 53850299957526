import { template as template_5f73605a822b4d038e9299916ee28c7e } from "@ember/template-compiler";
const FKLabel = template_5f73605a822b4d038e9299916ee28c7e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
