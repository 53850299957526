import { template as template_22230a63a98d451d8a0cbb63e01e75c4 } from "@ember/template-compiler";
const WelcomeHeader = template_22230a63a98d451d8a0cbb63e01e75c4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
