import { template as template_135d61372f534cc587f134d01d40340e } from "@ember/template-compiler";
const FKControlMenuContainer = template_135d61372f534cc587f134d01d40340e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
