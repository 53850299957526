import { template as template_bdd7ac9fa85240458c6a74a51ddfd9ce } from "@ember/template-compiler";
const FKText = template_bdd7ac9fa85240458c6a74a51ddfd9ce(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
